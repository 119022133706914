@import "../variables.css";

.sidebar {
    height: calc(var(--set-height));
    width: 50px;
    background-color: var(--bg-color);
    position: absolute;
    top: calc(100vh - var(--set-top));
    left: 0;
    border: 1px solid var(--border-color);
    border-bottom: none;
}

.sidebar__icon {
    width: 40px;
    height: 40px;
    margin: 4px;
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    cursor: pointer;
}

.editor-container {
    height: var(--set-height);
    width: 50vw;
    background-color: var(--bg-editor-color);
    position: absolute;
    top: calc(100vh - var(--set-top));
    left: 52px;
}

.editor-container__header {
    height: var(--header-height);
    width: 100%;
    background-color: var(--bg-color);
    display: flex;
    justify-content: center;
}

.editor-container__filename {
    padding: 14px 20px;
    font-size: 16px;
    background-color: var(--bg-editor-color);
    display: inline-flex;
    align-items: center;
    line-height: 20px;
    font-weight: 500;
    color: var(--text-color);
    border-top: 1px solid var(--border-color);
}

.editor-container__controls {
    height: 48px;
    width: 100%;
    border: 1px solid var(--border-color);
    border-right: none;
}

.editor-container__run-button {
    width: var(--button-width);
    height: var(--button-height);
    margin: 9px 20px;
    justify-content: center;
    cursor: pointer;
    background: var(--primary-button-bg);
    font-size: var(--button-font-size);
    line-height: 20px;
    color: var(--primary-button-color);
    border: none;
    outline: none;
    border-radius: var(--button-radius);
    font-weight: var(--button-font-weight);
    float: right;
}

.editor {
    height: calc(100% - var(--header-height) - 10px);
    margin-top: 10px;
    width: 100%;
}

.output-container {
    height: var(--set-height);
    width: calc(50vw - 53px);
    background-color: var(--bg-editor-color);
    position: absolute;
    top: calc(100vh - var(--set-top));
    right: 0;
    color: var(--text-color);
    border-left: 1px solid var(--border-color);
}

.output-container__header {
    height: 48px;
    width: 100%;
    background-color: var(--bg-color);
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.output-container__title {
    padding: 14px 20px;
    font-size: 16px;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    line-height: 24px;
    font-weight: 500;
}

.output-container__clear-button {
    width: var(--button-width);
    height: var(--button-height);
    margin: 9px 20px;
    justify-content: center;
    cursor: pointer;
    background: var(--bg-color);
    font-size: var(--button-font-size);
    line-height: 20px;
    color: var(--text-color);
    border: 2px solid #d1dbe5cc;
    outline: none;
    border-radius: var(--button-radius);
    font-weight: var(--button-font-weight);
}

.output-container__content {
    height: calc(100% - var(--header-height));
    width: 100%;
}

.output-display {
    white-space: pre-line;
    position: relative;
    top: 2%;
    left: 2%;
    width: 96%;
    height: 96%;
    overflow-y: auto;
}

.output-display::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: var(--border-color);
    border-radius: 10px;
}

.output-display::-webkit-scrollbar
{
	width: 6px;
	background-color: var(--border-color);
    border-radius: 10px;
}

.output-display::-webkit-scrollbar-thumb
{
	background-color: var(--bg-color);
	border: 1px solid var(--border-color);
    border-radius: 10px;
}